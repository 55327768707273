import React from 'react'
import facebook from '../assets/img/facebook.svg'
import instagram from '../assets/img/instagram.svg'
import linkedin from '../assets/img/linkedin.svg'

class SocialMediaNav extends React.Component{
    render(){
        return(
            <ul className="ldb_list-social">
                {/* <li>
                    <a href="https://www.facebook.com/LDBConstruction/" target="_blank" rel="noopener noreferrer" title="Go to facebook">
                        <img src={facebook} alt="facebook" />
                    </a>                        
                </li> */}
                <li>
                    <a href="https://www.linkedin.com/company/ldb-construction-uk" target="_blank" rel="noopener noreferrer" title="Go to linkedin">
                        <img src={linkedin} alt="linkedin" />
                    </a>                        
                </li>                
                <li>
                    <a href="https://www.instagram.com/ldb_construction/" target="_blank" rel="noopener noreferrer" title="Go to instagram">
                        <img src={instagram} alt="instagram" />
                    </a>                        
                </li>
            </ul>
        )
    }
}

export default SocialMediaNav